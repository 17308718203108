<template>
  <div>
    <Navbar page="Canal de tratamento de dados" link="/cadastros" nameLink="Cadastros" />
    <div class="mx-4 my-4 md:mx-8 md:my-8">

      <h1 class="text-3xl mb-5">Canal de tratamento de dados</h1>

      <div class="grid grid-cols-12 gap-6 mb-3">
        <div class="col-span-12 xl:col-span-2">
          <router-link :to="`/cadastros/${route}/form`" type="button" 
            :style="{ 'background-color': $store.state.colorPrincipal, 'color': invertColor($store.state.colorPrincipal, true) }"
            class="inline-block transition duration-200 bg-yellow-300 hover:bg-yellow-400 
            focus:bg-yellow-700 focus:shadow-sm focus:ring-4 
            focus:ring-yellow-500 focus:ring-opacity-50 text-white py-2 
            rounded-lg text-sm shadow-sm hover:shadow-md w-40 font-semibold text-center w-full
            "
          >
              <span class="inline-block mr-2">Novo</span>
          </router-link>
        </div>
        <div class="col-span-12 xl:col-span-2">
          <a :href="`${url_api}/v1/${route}/exportar/${$store.state.empresa._id}?token=${$store.state.token}`" target= "_blank" rel="noreferrer" class="bg-blue-400 hover:bg-blue-500 focus:bg-blue-500 focus:shadow-sm focus:ring-4 text-white w-full flex py-2 rounded-lg text-sm shadow-sm hover:shadow-md w-40 font-semibold justify-center">
              <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4 mt-1 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M9 19l3 3m0 0l3-3m-3 3V10" />
              </svg>
              Download base 
          </a>
        </div>
        <div class="col-span-12 xl:col-span-2">
          <button @click="$modal.show('importarModal')" class="bg-green-400 hover:bg-green-500 focus:bg-green-500 focus:shadow-sm focus:ring-4 text-white w-full flex py-2 rounded-lg text-sm shadow-sm hover:shadow-md w-40 font-semibold justify-center">
            <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4 mt-1 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12" />
            </svg> 
            Importar base 
          </button>
        </div>
        <div class="col-span-12 xl:col-span-2">
          <button @click="excluirSelecionadas" v-if="selecionados.length" type="button" class="transition duration-200 bg-red-500 hover:bg-red-700 text-white py-2 rounded-lg text-sm shadow-sm hover:shadow-md w-full md:w-72 font-semibold text-center mb-2 md:ml-4">
            <span class="inline-block">Excluir selecionadas</span>
          </button>
        </div>
      </div>

      <div class="grid grid-cols-12 gap-6 mb-3">
        <div class="col-span-12 xl:col-span-10 mb-1 mt-1">
            <input v-model="busca" type="text" name="busca" id="busca" placeholder="Filtrando Nome" class="mt-1 focus:ring-yellow-400 focus:border-yellow-400 block w-full shadow-sm sm:text-sm border-gray-300 rounded-3xl">
        </div>
        <div class="col-span-12 md:col-span-2 mb-1 mt-2">
          <button @click="updateFiltro" class="bg-gray-500 hover:bg-gray-600 focus:bg-gray-700 focus:shadow-sm focus:ring-opacity-50 text-white py-2 rounded-3xl text-sm shadow-sm hover:shadow-md w-full font-semibold text-center">Buscar </button>
        </div>
      </div>

      <div class="flex flex-col">
        <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
            <div class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
              <table class="min-w-full divide-y divide-gray-200">
                <thead class="bg-gray-50">
                  <tr>
                    <th scope="col" class="px-3 py-3 text-left text-xs font-medium text-gray-500 tracking-wider cursor-pointer">
                      <svg @click="selecionarTodos" xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M10.325 4.317c.426-1.756 2.924-1.756 3.35 0a1.724 1.724 0 002.573 1.066c1.543-.94 3.31.826 2.37 2.37a1.724 1.724 0 001.065 2.572c1.756.426 1.756 2.924 0 3.35a1.724 1.724 0 00-1.066 2.573c.94 1.543-.826 3.31-2.37 2.37a1.724 1.724 0 00-2.572 1.065c-.426 1.756-2.924 1.756-3.35 0a1.724 1.724 0 00-2.573-1.066c-1.543.94-3.31-.826-2.37-2.37a1.724 1.724 0 00-1.065-2.572c-1.756-.426-1.756-2.924 0-3.35a1.724 1.724 0 001.066-2.573c-.94-1.543.826-3.31 2.37-2.37.996.608 2.296.07 2.572-1.065z" />
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                      </svg>
                    </th>
                    <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 tracking-wider">
                      Nome
                    </th>
                    <th v-if="!$store.state.empresa" scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 tracking-wider">
                      Empresa
                    </th>
                    <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 tracking-wider">
                      Opções
                    </th>
                  </tr>
                </thead>
                <tbody class="bg-white divide-y divide-gray-200">
                  <tr v-for="item in list" :key="item._id">
                    <td class="px-3 py-4 whitespace-nowrap">
                      <div class="flex items-center">
                        <input :checked="selecionados.map(el => el._id).indexOf(item._id) > -1" @change="(e) => selecionadosControl(item, e)" type="checkbox" class="rounded-sm">
                      </div>
                    </td>
                    <td class="px-6 py-4 whitespace-nowrap">
                      <div class="flex items-center">
                        <div class="text-sm text-gray-500">
                          {{item.nome ? item.nome : ''}}
                        </div>
                      </div>
                    </td>

                    <td v-if="!$store.state.empresa" class="px-6 py-4 whitespace-nowrap">
                      <div class="flex items-center">
                        <div class="text-sm text-gray-500">
                          {{ item.empresa ? item.empresa.nome : ''}}
                        </div>
                      </div>
                    </td>

                  
                    <td class="px-6 py-4 whitespace-nowrap text-sm font-medium">

                      <router-link 
                      :to="`/cadastros/${route}/form/${item._id}`"
                      class="text-blue-500 rounded py-1 px-4">
                      Editar
                      </router-link>

                      <button
                      @click="remove(item._id)" 
                      href="#" 
                      class="text-white hover:bg-red-500 bg-red-700 rounded py-1 px-4">
                      Excluir
                      </button>

                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <pagination v-if="total > 0" v-model="query.page" :per-page="perPage" :records="total" @paginate="setPage" class="pagination" />
          </div>
        </div>
      </div>
      <modal name="importarModal" :adaptive="true" :height="'auto'">
        <div class="px-5 py-5">
          <h2 class="text-xl mb-2 font-bold mb-2"> Importar base</h2>
          <div class="grid grid-cols-12 gap-4">
            <div class="col-span-12">
                <a target= "_blank" rel="noreferrer" :href="`/arquivo-modelo.csv`" class="text-sm underline">Exemplo arquivo modelo importação</a>
            </div>
            <div class="col-span-12">
              <vue-dropzone ref="doc" id="doc" class="w-full" :options="dropzoneOptions" @vdropzone-sending="send" v-on:vdropzone-success="update">
              </vue-dropzone>
            </div>
          </div>
        </div>
      </modal>
    </div>
  </div>
</template>

<script>
import vueDropzone from 'vue2-dropzone';
import 'vue2-dropzone/dist/vue2Dropzone.min.css';
export default {
  components: {
    vueDropzone
  },
  data() {
    return {
      route: 'canais',
      list: [],
      selecionados: [],
      page: 1,
      perPage: 10,
      busca: '',
      total: 0,
      query: { page: 1, limit: 10, skip: 0  },
      dropzoneOptions: this.$http.getDropzoneConfig(
        `v1/canais/importar-csv`,
        'post',
      {
        maxFiles: 1,
        addRemoveLinks: true,
        capture: true,
        dictDefaultMessage: 'Clique ou arraste seu arquivo aqui',
        dictRemoveFile: 'Remover'
      }),
    }
  },
  methods: {

    async start() {
      const listReq = await this.$http.post(`/v1/${this.route}/list`, { busca: this.busca, skip: this.query.skip, limit: this.query.limit });
      this.list = listReq.data.data;
      this.total = listReq.data.total;
    },

    async excluirSelecionadas(){
      this.$confirm({
        title: 'Remover selecionados',
        message: `Deseja remover itens selecionados ?`,
        button: {
            no: 'Não',
            yes: 'Sim',
        },
        callback: async confirm => {
          if(!confirm) return;
          await this.$http.post(`/v1/${this.route}/selecionados/deletar`,{ selecionados: this.selecionados });
          this.selecionados = [];
          this.$vToastify.success("Removido com sucesso!");
          this.start();
          this.grafico();
        }
      })
    },
    
    async remove(id) {
      this.$confirm({
          title: 'Confirmação',
          message: `Tem certeza que deseja arquivar esse canal?`,
          button: {
              no: 'Não',
              yes: 'Sim',
          },
          callback: async confirm => {
            if (confirm) {
              await this.$http.delete(`/v1/${this.route}/${id}`);
              this.$vToastify.success("Removido com sucesso!");
              this.page = 1;
              this.start();
            }
          }
      });
    },

    update(file, response) {
      if(response.success) this.$vToastify.success("Importando! Quando finalizado você será alertado no email.");
      else this.$vToastify.error(response.err);
      this.$modal.hide('importarModal');
      this.start();
    },

    send(file, xhr, formData) {
      formData.append('empresa', this.$store.state.empresa && this.$store.state.empresa._id ? this.$store.state.empresa._id : null);
    },

    updateFiltro(){
      this.query.page = 1;
      this.query.limit = 10;
      this.query.skip = 0;

      this.query = { page: this.query.page, limit: this.query.limit, skip: this.query.skip, busca: this.busca };
      this.$router.push({ path: `/cadastros/${this.route}`, query: this.query });
    },

    async selecionarTodos(){
      const listReq = await this.$http.post(`/v1/${this.route}/list`, { all: true, busca: this.busca });
      this.selecionados = listReq.data.data;
    },

    selecionadosControl(item, e){
      if (this.selecionados.map(el => el._id).indexOf(item._id) > -1 && !e.target.checked) {
        this.selecionados.splice(this.selecionados.map(el => el._id).indexOf(item._id), 1);
      } else {
        this.selecionados.push(item);
      }
    },

    async setPage(page) {
      const skip = (page - 1) * this.perPage;
      const limit = this.perPage;

      this.query.page = page;
      this.query.skip = skip;
      this.query.limit = limit;
      this.query.busca = this.busca;

      this.$router.push({ path: `/cadastros/${this.route}`, query: this.query });
    }
  },
  async beforeRouteUpdate(to, from, next) {
    next();

    if(this.$route.query.busca) this.busca = this.$route.query.busca;

    if(this.$route.query.page) this.query.page = parseInt(this.$route.query.page);
    if(this.$route.query.limit) this.query.limit = parseInt(this.$route.query.limit);
    if(this.$route.query.skip) this.query.skip = parseInt(this.$route.query.skip);

    this.start();
  },
  async beforeMount() {
    if(this.$route.query.busca) this.busca = this.$route.query.busca;

    if(this.$route.query.page) this.query.page = parseInt(this.$route.query.page);
    if(this.$route.query.limit) this.query.limit = parseInt(this.$route.query.limit);
    if(this.$route.query.skip) this.query.skip = parseInt(this.$route.query.skip);

    this.start();
  },
}
</script>